import React from 'react';
import './modal.css';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router';
import { setUserDetails,getUserDetails } from '../../_helper/authentication';
import axios from '../../_config/axios';

const Modal = (props) => {
  const { closeModal } = props;
  const [msisdn,setmsisdn]= React.useState("");
  const [checkMsisdn,setCheckMsisdn]= React.useState(false);

  const closeicon = () => (
    <FontAwesome
    name="times"
    onClick={closeModal}
    style={{
      color: '#000000',
      padding: '10px',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 0,
      position: 'absolute',
      top: '0.3rem',
      right: '0.5rem',
    }}
    />
  );

  // const dailysub = () =>{
  //   console.log("hi")
  //   console.log(msisdn)
  //   const payload = new FormData();
  //   payload.append('msisdn', msisdn);
  //   payload.append('username', getUserDetails().username);
  //   axios.post('Subscription/unsub_refcode', payload)
  //       .then(res => {
  //           if (res.status= '200'){

  //             localStorage.clear();
  //             window.location.reload("/");

  //           }
  //       })
  //       .catch(err => console.log(err));

  // }

  const dailysub_old = () =>{
    console.log("hi")
    console.log(msisdn)

      const payload = new FormData();
      payload.append('phone_no', msisdn);
      axios.post('StageGoalyApi/check_login_status', payload)
          .then(res => {
              console.log(res)
              if(res.data.status == '200'){
                  if (res.data.data.user_details.status== 'active'){
                          
                      setCheckMsisdn(true);
                  }else{

                    props.history.push({
                      pathname: `Package/${msisdn}`,
                      state: { msisdn: msisdn }
                    })
                    window.location.reload();
                  }
              }else{

                  props.history.push({
                      pathname: `Package/${msisdn}`,
                      state: { msisdn: msisdn }
                    })
                    window.location.reload();
              }
          })
          .catch(err => console.log(err));   
  }

  const dailysub = () =>{

    window.location.href="http://omantel.goaly.mobi:8708/lp1?adnet=portal"

  }

  const weeklysub = () =>{

    window.location.href=" http://omantel.goaly.mobi:8708/lp2?adnet=portal"

  }
  const handlechange =(e)=>{
      setmsisdn(e.target.value)
  }

  


  return (
   
      <div className="content" style={{zIndex:'9999',top:'37%',left:'50%',transform:'translate(-50%, -50%)',width:'80%',borderRadius:'10px',textAlign:'center'}}>
        { closeicon() }
        {/* {props.children} */}
        <span style={{fontSize:'24px',fontFamily:'emoji'}}> Choose Subscription Type </span>
       <div style={{marginTop:'22px'}}>
       <button style={{marginTop:10,backgroundColor:'green',marginRight:'10px'}} onClick={dailysub}>Daily</button>
        <button style={{marginTop:10,backgroundColor:'green'}} onClick={weeklysub}>Weekly</button>
       </div>

      </div>
    
  );
};


export default withRouter(Modal);